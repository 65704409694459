.icp-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.icp-h-center {
  display: flex;
  align-items: center;
}

.icp-v-center {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.icp-action-button {
  flex: none;
  box-sizing: border-box;
  display: inline-flex;
  gap: 6px;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
  min-width: 32px;
  height: 32px;
  background: none;
  border: 0;
  border-radius: calc(var(--border-radius));
  padding: 0 8px;
  white-space: nowrap;
  cursor: pointer;

  & svg {
    color: #5f6368;
  }

  &:hover,
  &.selected {
    background: rgb(0 0 0 / 6%);
  }

  &:active {
    background: rgb(0 0 0 / 10%);
  }

  &:disabled {
    background: none;
    color: rgb(0 0 0 / 30%);
    cursor: default;

    & svg {
      color: inherit;
    }
  }
}

.icp-clickable {
  &:hover,
  &.selected {
    background: rgb(0 0 0 / 6%);
  }

  &:active {
    background: rgb(0 0 0 / 10%);
  }

  &.disabled {
    background: none;
    color: rgb(0 0 0 / 30%);
  }
}

.icp-bordered {
  border: 1px solid var(--border-color);
}

.icp-backdrop {
  position: fixed;
  inset: 0;
  z-index: -1;
}

.icp-overlay {
  position: fixed;
  inset: 0;
  z-index: var(--max-z-index);
  pointer-events: none;
  overflow: hidden;

  & > * {
    pointer-events: auto;
  }
}

.icp-thin-scrollbar,
.ag-column-select-virtual-list-viewport,
.rc-virtual-list-holder {
  &::-webkit-scrollbar {
    height: 12px;
    width: 12px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgb(100 100 100 / 40%);
    background-clip: content-box;
    border-radius: 10px;
    border: 3px solid transparent; /* border to simulate padding */
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: rgb(100 100 100 / 70%);
  }
}

.text-ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.icp-beta-info {
  color: var(--helper-color);
  margin-bottom: 8px;
  font-size: 12px;
}

.icp-beta-icon {
  font-size: 12px;
  color: #fff;
  background-color: var(--primary-color);
  border-radius: 4px;
  padding: 2px 4px;

  &:not(:first-child) {
    margin-left: 2px;
  }

  &:not(:last-child) {
    margin-right: 2px;
  }
}
