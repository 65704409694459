.icp-scan-code {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 30;
  background: rgb(0 0 0 / 60%);
  display: flex;
  align-items: center;
  justify-content: center;
}

@supports (height: 100dvh) {
  .icp-scan-code {
    height: 100dvh;
  }
}

.icp-scan-code-video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.icp-scan-code-panel {
  position: absolute;
  top: 0;
  left: 0;
  padding: 32px;
  width: 100%;
  height: 100%;
  z-index: 31;
  background: transparent;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  & .icp-scan-code-panel-header {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
  }

  & .icp-scan-code-panel-footer {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
  }

  & .icp-scan-code-panel-button {
    width: 48px;
    height: 48px;
    border-radius: 24px;
  }
}
