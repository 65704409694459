.icp-resize-control {
  position: absolute;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
  overflow: hidden;

  &.hover-effect {
    &.line-horizontal,
    &.line-vertical {
      &::after {
        content: '';
        position: absolute;
        border-radius: 2px;
        transition: background-color linear 0.2s;
      }

      &:hover {
        &::after {
          background-color: var(--border-color);
        }
      }
    }
  }

  &.line-horizontal {
    width: 6px;
    height: 100%;

    &::after {
      left: 2px;
      top: 0;
      width: 2px;
      height: 100%;
    }
  }

  &.line-vertical {
    width: 100%;
    height: 6px;

    &::after {
      left: 0;
      top: 2px;
      width: 100%;
      height: 2px;
    }
  }

  &.top {
    top: 0;
    left: 0;
    transform: translateY(-50%);
    cursor: row-resize;
  }

  &.right {
    top: 0;
    right: 0;
    transform: translateX(50%);
    cursor: col-resize;
  }

  &.bottom {
    left: 0;
    bottom: 0;
    transform: translateY(50%);
    cursor: row-resize;
  }

  &.left {
    left: 0;
    top: 0;
    transform: translateX(-50%);
    cursor: col-resize;
  }

  &.corner {
    width: 20px;
    height: 20px;
  }

  &.top-left {
    top: 0;
    left: 0;
    transform: translate(-50%, -50%);
    cursor: nwse-resize;
  }

  &.top-right {
    top: 0;
    right: 0;
    transform: translate(50%, -50%);
    cursor: nesw-resize;
  }

  &.bottom-left {
    bottom: 0;
    left: 0;
    transform: translate(-50%, 50%);
    cursor: nesw-resize;
  }

  &.bottom-right {
    bottom: 0;
    right: 0;
    transform: translate(50%, 50%);
    cursor: nwse-resize;
  }
}

.icp-resizable {
  &.is-all {
    & .icp-resize-control {
      &.top {
        cursor: ns-resize;
      }

      &.right {
        cursor: ew-resize;
      }

      &.bottom {
        cursor: ns-resize;
      }

      &.left {
        cursor: ew-resize;
      }
    }
  }
}

