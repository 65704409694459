div[class*='ag-theme-'] {
  --ag-data-color: var(--font-color);
  --ag-font-family: var(--font-family);
  --ag-font-size: var(--font-size);
  --ag-input-border-color: var(--ag-border-color);
  --ag-input-focus-border-color: var(--primary-color-hover);
  --ag-input-focus-box-shadow: 0 0 0 2px var(--primary-color-bg);
  --ag-row-hover-color: rgb(0 0 0 / 4%);
  --ag-range-selection-border-color: var(--primary-color);
  --ag-checkbox-checked-color: var(--primary-color);
  --ag-range-selection-background-color: rgb(0 0 0 / 10%);
  --ag-popup-shadow: 0 6px 16px 0 rgb(0 0 0 / 8%), 0 3px 6px -4px rgb(0 0 0 / 12%), 0 9px 28px 8px rgb(0 0 0 / 5%);
  --ag-header-foreground-color: rgb(51 51 51 / 70%);
  --ag-header-background-color: white;
  --ag-background-color: white;
  --ag-odd-row-background-color: white;
  --ag-menu-background-color: var(--ag-background-color);
  --ag-panel-background-color: white;
  --ag-border-color: #e8e8e8;
  --ag-row-border-style: solid;
  --ag-row-border-width: 1px;
  --ag-row-border-color: #e8e8e8;
  --ag-border-radius: 4px;
  --ag-header-height: 41px;
  --ag-row-height: 41px;
  --ag-cell-horizontal-padding: 8px;
  --ag-cell-widget-spacing: 8px;
  --ag-header-column-resize-handle-width: 1px;
  --ag-header-column-resize-handle-color: #e8e8e8;
  --ag-header-column-resize-handle-height: 50%;
  --ag-control-panel-background-color: white;
  --ag-grid-size: 4px;
  --ag-list-item-height: 24px;
  --ag-selected-row-background-color: rgb(24 144 255 / 16%);
  --ag-wrapper-border-radius: 0;

  -webkit-font-smoothing: initial;

  &.small {
    --ag-header-height: 32px;
    --ag-row-height: 33px;
  }

  &.large {
    --ag-header-height: 48px;
    --ag-row-height: 80px;

    & .ag-body-viewport {
      padding-top: 8px;
    }
  }

  & .ag-root-wrapper {
    border-radius: var(--ag-wrapper-border-radius);
    border: 0;

    & .ag-column-drop-cell {
      height: calc(var(--ag-grid-size) * 6);
      background: white;
    }
  }

  &.no-toolbar .ag-root-wrapper {
    border-top: 1px solid var(--ag-border-color);
  }

  & .ag-tab {
    padding: calc(var(--ag-grid-size) * 2);
  }

  & .ag-input-field-input,
  & .ag-text-field-input {
    height: 24px;
    border-radius: var(--ag-border-radius);
  }

  & .ant-select-selector {
    font-size: var(--ag-font-size);
  }

  & .ag-picker-field-wrapper {
    height: 24px;
    border-radius: var(--ag-border-radius);

    &:focus {
      border-color: var(--ag-input-focus-border-color);
    }
  }

  & .ag-header-cell:not(.ag-floating-filter) {
    font-weight: normal;
  }

  & .ag-header-cell-comp-wrapper {
    position: relative;
    overflow: visible;
  }

  & .ag-ltr .ag-floating-filter-button {
    margin-left: var(--ag-grid-size);
  }

  & .ag-checkbox-input-wrapper:active,
  & .ag-checkbox-input-wrapper:focus-within {
    box-shadow: none;
  }

  & .ag-cell-wrapper {
    height: 100%;
  }

  & .ag-cell[col-id='ag-Grid-AutoColumn'] {
    padding-left: 12px;
  }

  & .ag-cell[aria-expanded] .ag-cell-value {
    overflow: visible;
    min-width: 0;
  }

  & .ag-row-group {
    align-items: center;
  }

  /* toolbar 已经显示了 filter，column 上就不用显示了 */

  & .ag-filter-icon {
    display: none;
  }

  & .ag-header-menu-icon {
    opacity: 0;
  }

  & .ag-header-cell:hover .ag-header-menu-icon {
    opacity: 1;
  }

  & .ag-menu {
    border: 0;
    border-radius: var(--border-radius);
    color: var(--font-color);
  }

  & .ag-menu-list {
    padding: 4px;
  }

  & .ag-menu-option {
    font-weight: normal;
    border-radius: 4px;
  }

  & .ag-menu-option-part {
    padding-top: 8px;
    padding-bottom: 8px;
  }

  & .ag-menu-option-icon {
    padding-left: 8px;
  }

  & .ag-menu-option-text {
    padding-left: 8px;
    padding-right: 8px;
  }

  & .ag-menu-option-active {
    background: transparent;

    & > * {
      background: var(--ag-row-hover-color);
    }

    & > :first-child {
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
    }

    & > :last-child {
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
    }
  }

  & .ag-icon {
    color: #5f6368;
  }

  & .ag-icon-menu-alt {
    /* 默认 icon 太显眼了缩小一点 */
    transform: scale(0.9);
  }

  & .ag-panel {
    border: 0;
  }

  & .ag-overlay-loading-wrapper {
    background-color: rgb(255 255 255 / 50%);
  }

  & .ag-row-number-cell {
    font-weight: normal;
  }
}

.icp-ag-table {
  box-sizing: border-box;
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  outline: none;

  &.bordered {
    border: 1px solid var(--ag-border-color);
    border-radius: 8px;
    overflow: hidden;

    & .ag-root-wrapper {
      border: 0;
      /* border-bottom: 0; */
      /* border-top: 1px solid var(--ag-border-color); */
    }

    & .icp-toolbar {
      padding: 8px;
    }


    /* &.no-toolbar .ag-root-wrapper {
      border-top: 1px solid var(--ag-border-color);
    } */
  }

  &.show-client-side-loading {
    & .ag-overlay-no-rows-wrapper {
      /* 自己模拟 client side loading 的时候会穿空数组给 ag-grid，空数组会显示 no data found */
      opacity: 0;
    }
  }

  & .ag-cell.ag-cell-inline-editing {
    display: flex;
    align-items: center;
    padding-left: var(--ag-cell-horizontal-padding);
    padding-right: var(--ag-cell-horizontal-padding);
    border: 0 !important;
    background: transparent;
    box-shadow: none;

    & .input-element {
      min-height: 0;
      width: 100%;
    }
  }

  & .ag-status-bar {
    padding: 0 8px;
    height: 40px;
    align-items: center;
    overflow: visible; /* otherwise antd pagination size changer cannot display */
  }

  & .icp-table-total-rows {
    gap: 4px;
  }

  /* & .ant-pagination {
    font-size: 13px;

    & .ant-select-single.ant-select-sm .ant-select-selector,
    & .ant-select-dropdown .ant-select-item {
      font-size: 13px;
    }
  }

  & .ant-pagination.ant-pagination-mini .ant-pagination-item {
    height: auto;
    font-weight: 400;
  }

  & .ant-pagination.ant-pagination-mini .ant-pagination-item:hover {
    color: var(--font-color);
  }

  & .ant-pagination.ant-pagination-mini .ant-pagination-item-active {
    border: none;
  }

  & .ant-pagination.ant-pagination-mini .ant-pagination-item-active a {
    font-weight: bold;
    color: var(--font-color);
  }

  & .ant-pagination.ant-pagination-mini .ant-pagination-item-active:hover {
    color: var(--font-color);
  }

  & .ant-pagination.ant-pagination-mini .ant-pagination-item-active:hover a {
    color: var(--font-color);
  }

  & .ant-pagination.ant-pagination-mini .ant-pagination-options {
    margin-left: 4px;
  }

  & .ant-pagination.ant-pagination-mini .ant-pagination-options-quick-jumper {
    font-weight: 400;
  } */

  /* & .ag-header-icon.ag-header-cell-menu-button:hover {
    border-radius: 1px;
    background-color: rgb(0 0 0 / 6%);
    box-shadow: 0 0 0 2px rgb(0 0 0 / 6%);
    color: inherit;
    cursor: pointer;
  } */

  & .ag-cell-label-container {
    /* 否则 .ag-header-cell-menu-button:hover 的 box-shadow 会被挡住一部分 */
    /* overflow: visible; */
  }
}

.icp-table-client-side-loading {
  position: absolute;
  inset: 0;
  background: var(--ag-modal-overlay-background-color);
  z-index: 1;
}

.icp-table-setting-label {
  font-weight: 500;
}

.icp-table-total-rows {
  gap: 6px;
  color: var(--font-color);
  font-weight: normal;
}

.icp-table-row-disabled.icp-table-row-disabled {
  color: rgb(0 0 0 / 20%);
}

/* server side header need to custom a checkbox, ag-grid not provided */
.icp-server-side-checkbox-header {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: space-between;
  width: 100%;

  & .ant-checkbox-wrapper {
    width: 16px;
    height: 16px;
    margin-right: var(--ag-cell-widget-spacing);

    & .ant-checkbox-input {
      cursor: default;
    }
  }

  & .ant-checkbox {
    top: 0;

    & .ant-checkbox-inner {
      border-radius: 2px; /* 为了和 ag-grid row checkbox 统一 */
    }

    &:not(.ant-checkbox-checked) {
      & .ant-checkbox-inner {
        border: 2px solid #999;
      }
    }
  }
}

.ag-input-wrapper.icp-custom-date-component {
  & input {
    font-size: 13px;
  }
}
