.ai-agent-float-button {
  --ai-agent-float-btn-size: 50px;

  width: var(--ai-agent-float-btn-size);
  height: var(--ai-agent-float-btn-size);

  &.ant-float-btn .ant-float-btn-body .ant-float-btn-content {
    padding: 0;

    & .ant-float-btn-icon {
      width: var(--ai-agent-float-btn-size);
      height: var(--ai-agent-float-btn-size);

      & img {
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        user-select: none;
        pointer-events: none;
      }
    }
  }
}

#root.ai-agent-float-button-parent {
  display: flex;
  flex-direction: row;

  & .login-page,
  & .ant-result-404,
  & .app-wrapper,
  & .template-wrapper {
    overflow-y: auto;
    flex: 1;
  }
}

.ai-agent-right-side-panel {
  width: 400px;
  display: flex;
  flex-direction: column;
  background-color: #f0f5ff;
  border-left: 1px solid var(--border-color);

  & .ai-agent {
    flex: 1;

    & .ai-agent-input-actions {
      margin-right: 8px;
    }
  }
}

.ai-agent-float-button-parent:has(.mobile-template1) > .ai-agent-right-side-panel {
  position: fixed;
  top: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  z-index: var(--max-z-index);
}
