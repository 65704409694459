.permissions-element {
  &.permissions-element {
    width: 100%;
    height: 80vh;
    display: flex;
    flex-direction: column;
    align-items: stretch;
  }

  & .permissions-element-table-toolbar {
    display: flex;
    flex-direction: row;
    gap: 8px;
  }
}
